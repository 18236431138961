export const state = () => ({
    isCypress: false,
    isMobile: false,
    isTab: false,
    isSafari: false,
    userAgent: false,
    advancedOptionListResponsiveView: false,
    showAutoSavetoDriveOption: false,
    googleDriveTokenForUpload: null,
    telemetryUser: false,
    dialogIsOpen: false,
    country: 'US',
    priceToLong: false
})

export const mutations = {
    updateIsCypress(state, payload) {
        state.isCypress = payload
    },
    updateIsMobile(state, payload) {
        state.isMobile = payload
    },
    updateIsTab(state, payload) {
        state.isTab = payload
    },
    updateIsSafari(state, payload) {
        state.isSafari = payload
    },
    updateUserAgent(state, payload) {
        state.userAgent = payload
    },
    setShowAutoSavetoDriveOption(state, payload) {
        state.showAutoSavetoDriveOption = payload
        if (payload === false) state.googleDriveTokenForUpload = null
    },
    setGoogleDriveTokenForUpload(state, payload) {
        state.googleDriveTokenForUpload = payload
    },
    updateAdvancedOptionListResponsiveView(state, status) {
        state.advancedOptionListResponsiveView = status
    },
    setTelemetryUser(state, value) {
        state.telemetryUser = value;
    },
    setDialogIsOpen(state, value) {
        state.dialogIsOpen = value;
    },
    setCountry(state, value) {
        state.country = value;
    },
    setPriceToLong(state, value) {
        state.priceToLong = value;
    }
}

export const strict = false
