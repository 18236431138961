/* eslint-disable no-undef */
import {
    STATUS_DONE,
} from "../fc/Constants/job-status";
import { aesGcmEncrypt, aesGcmDecrypt } from "../fc/utils/crypt";
import { getPresets } from "../fc/Dal/OptionsRepo";
import { getSubscription, getSubscriptionSettings } from "~/fc/Dal/AuthRepo";
const pass = "H0t2HVchO7fBKmClUiH11UId8GLUGG";
import { uuidv4 } from "~/fc/File/FileHelpers";

export const state = () => ({
    targetChangeIndex: 0,
    breadcrumbs: [],
    completed: [],
    download: false,
    alert: false,
    types: false,
    presetsList: [],
    list: [],
    reloadedList: [],
    fileInputConcurrentLength: 0,
    history: [],
    activeFilesCount: 0,
    showAdvancedForm: false,
    showInvalidPopup: false,
    showOverLimitPopup: false,
    smallUploaderDisabled: true,
    subscription: {
        minutes: 25,
        name: "Free",
        perTaskLimit: 1,
        plan: {
            maxFileSizeInGb: 1,
        },
        loaded: false,
        free: null,
    },
    googleToken: {},
    errors: [],
    info: {
        allowed: "",
        allowed_arr: [],
    },
    toasts: [],
    sidebarFolded: false,
    alertObj: null,
    redirect: "/",
    socketReconnectCounter: 0,
    isInternetAvailable: true,
    interruptedJobs: [],
    jobIDList: {},
    exportJobIDList: {},
    canChangeSubscription: true,
    interactionInitiated: false,
    loggedIn: false,
    documentMounted: false,
    autoSaveToDrive: false,
    extra: null,
    isValidDimension: true,
    toBeUploadedEvent: null // to handle file select event fired from outside the FileInputDropDown component.
});

export const mutations = {
    setIsValidVideoDimension(state, isValid) {
        state.isValidDimension = isValid;
    },
    setExtraTaskInfo(state, payload) {
        state.extra = payload;
    },
    setLoggedIn(state, payload) {
        state.loggedIn = payload;
    },
    setAutoSaveToDrive(state, payload) {
        state.autoSaveToDrive = payload;
    },
    setInteractionInitiated(state, payload) {
        state.interactionInitiated = payload;
    },
    /**
     * Add Item to the list
     * @param {object} state
     * @param {object} item
     */
    addFile(state, item) {
        state.list.push(item);
    },
    addExportJobID(state, item) {
        const _jobIDList = { ...state.exportJobIDList };
        _jobIDList[item.fileID] = item.jobID;
        state.exportJobIDList = _jobIDList;
    },
    addJobID(state, item) {
        const _jobIDList = { ...state.jobIDList };
        _jobIDList[item.fileID] = item.jobID;
        state.jobIDList = _jobIDList;
    },
    targetInitialized(state, file) {
        const index = state.list.findIndex((item) => item.id === file.id);
        if (state.list[index]) state.list[index].targetInitialized = true;
    },
    /**
     * Add Item to the list
     * @param {object} state
     * @param {object} item
     */
    add(state, item) {
        state.list.push(item);
    },
    setList(state, items) {
        state.list = items;
    },
    setReloadedList(state, items) {
        state.reloadedList = items;
    },
    setTypeForFile(state, { file, type }) {
        const index = state.list.findIndex((item) => item.id === file.id);
        state.list[index].type = type;
    },
    setSkipFile(state, { file, skip }) {
        const index = state.list.findIndex((item) => item.id === file.id);
        state.list[index].skip = skip;
    },
    removeSkipeedFiles(state) {
        state.list = state.list.filter((item) => !item.skip)
    },
    setExpiryForFile(state, file) {
        const index = state.list.findIndex((item) => item.id === file.id);
        if (state.list[index]) {
            state.list[index].expired = true;
        }
    },
    reOrderList(state, event) {
        state.list.splice(
            event.newIndex,
            0,
            state.list.splice(event.oldIndex, 1)[0]
        );
    },
    showError(store, name) {
        store[name] = true;
    },
    showInvalidPopup(store, msg) {
        store.showInvalidPopup = msg;
    },
    hideError(store) {
        store.showInvalidPopup = false;
        store.showOverLimitPopup = false;
    },
    updateInfo(store, info) {
        store.info = info;
    },
    updateInfoValue(store, { key, value }) {
        const info = { ...store.info };
        info[key] = value;
        store.info = info;
    },
    showAdvancedForm(state, val) {
        state.showAdvancedForm = val;
    },
    enableSmallUploader(store) {
        store.smallUploaderDisabled = false;
    },
    disableSmallUploader(store) {
        store.smallUploaderDisabled = true;
    },
    setInfo(state, info) {
        if (info.customVariables) {
            if (info.customVariables.enableSort) {
                info.limitAcceptedFiles = true;
            }
        }
        state.info = {
            ...info,
        };
    },
    setPageOptions(state, options) {
        state.info.advancedOptions = [...options];
    },
    /**
     * Remove an item from the list by id
     * @param {object} state
     * @param {string} id
     */
    remove(state, id) {
        // TODO: stop upload call if in progress
        for (let i = 0; i < state.list.length; i++) {
            if (state.list[i].id === id) {
                state.list.splice(i, 1);
                if (state.jobIDList[id]) delete state.jobIDList[id];
                break;
            }
        }
    },
    changeFileState(state, file) {
        state.list = [
            ...state.list.map((item) =>
                item.id !== file.id ? item : { ...item, ...file }
            ),
        ];
    },
    /**
     * Clear list
     * @param {object} state
     */
    clear(state) {
        state.fileInputConcurrentLength = 0;
        state.list = [];
        state.completed = [];
        state.forceTarget = false;
        state.failedUploadCalls = [];
        state.extra = null;
        state.isValidDimension = true;
    },
    forceTarget(state, target) {
        state.forceTarget = target;
    },
    addToCompleted(state, key) {
        state.completed.push(key);
    },
    setRedirect(state, url) {
        state.redirect = url;
    },
    addToHistory(state, item) {
        const list = localStorage.getItem("history")
            ? JSON.parse(localStorage.getItem("history")) || []
            : [];

        list.push({
            id: item.id,
            name: `${item.baseName}.${item.target.includes("compress") ? item.ext : item.target
                }`,
            download: item.download,
            status: item.status,
            sizeText: item.sizeText,
            sizeIncreased: item.sizeIncreased,
            size: item.size,
            newSize: item.newSize,
            convertedAt: Date.now(),
        });
        state.history = list;
        localStorage.setItem("history", JSON.stringify(list));
        state.activeFilesCount = [...list].filter(
            (item) => item.status === STATUS_DONE
        ).length;
    },
    deleteHistoryItems(state, item) {
        let list = localStorage.getItem("history")
            ? JSON.parse(localStorage.getItem("history")) || []
            : [];
        state.completed.forEach((url) => {
            const id = url.split("converted/")[1].split("/")[0];
            if (id) {
                list = list.filter((item) => item.id !== id);
            }
        });
        state.history = list;
        localStorage.setItem("history", JSON.stringify(list));
        state.activeFilesCount = [...list].filter(
            (item) => item.status === STATUS_DONE
        ).length;
    },
    updateActiveFileCount(state) {
        state.activeFilesCount = [...state.history].filter(
            (item) => item.status === STATUS_DONE
        ).length;
    },
    setDownloadPage(state, download) {
        state.download = download;
    },
    setConversionTypes(state, types) {
        state.types = types;
    },
    updateBreadcrumbs(state, items) {
        state.breadcrumbs = [...items];
    },
    targetChanged(state) {
        state.targetChangeIndex++;
    },
    setTmpPlan(store, item) {
        localStorage.setItem("selectedPlan", JSON.stringify(item));
    },
    setSubscription(state, subscription) {
        const name = subscription.planName || subscription.plan.name;
        state.subscription = {
            minutes: subscription.plan.conversionMinutes,
            name,
            perTaskLimit: subscription.perTaskLimit,
            plan: subscription.plan,
            loaded: true,
            free: name === "Free",
            maxMergeLimit: subscription?.plan?.maxMergeLimit,
            subscription: subscription
        };
        const expires = new Date(Date.now() + 86400 * 1000);

        if (name !== "Free") {
            $nuxt.$cookies.set("pro_user", true, {
                secure: false,
                expires,
                path: "/",
            });
        } else {
            $nuxt.$cookies.set("pro_user", false, {
                secure: false,
                expires,
                path: "/",
            });
        }
    },
    setGoogleTokens(state, token) {
        state.googleToken = token;
    },
    addToast(state, item) {
        if (state.toasts) {
            state.toasts.push(item);
        } else {
            state.toasts = [item];
        }
    },
    setToasts(state, token) {
        state.toasts = token;
    },
    toogleSidebar(state) {
        state.sidebarFolded = !state.sidebarFolded;
    },
    setAlert(state, obj) {
        state.alertObj = obj;
    },
    hideAlert(state) {
        state.alertObj = null;
    },
    removeAlert(state) {
        state.alert = false;
    },
    setProp(store, data) {
        store[data.key] = data.value;
        if (data.key === "history") {
            localStorage.setItem("history", JSON.stringify(data.value));
        }
    },
    setSocketReconnectAttempts(state, attempts) {
        state.socketReconnectCounter = attempts;
    },
    setInternetAvailability(state, status) {
        state.isInternetAvailable = status;
    },
    updateCanChangeSubscription(state, status) {
        state.canChangeSubscription = status;
    },
    addJobToInterruptedState(state, job) {
        const postArray = [...state.interruptedJobs];
        postArray.push(job);
        state.interruptedJobs = postArray;
    },
    removeJobsFromInterruptedState(state, { id, signature }) {
        const postArray = [...state.interruptedJobs];

        if (signature) {
            // merge-operation job has multiple upload files,
            // and multiple files of that merge job might get into the interrupted state during the 'validating'/'finishing' upload step
            // so, we have to remove by the unique file signature.
            state.interruptedJobs = postArray.filter(
                (job) => job.signature !== signature
            );
        } else {
            state.interruptedJobs = postArray.filter((job) => job.id !== id);
        }
    },
    savePresets(state, presets) {
        state.presetsList = presets;
    },
    setFileInputConcurrentLength(state, value) {
        state.fileInputConcurrentLength = value;
    },
    increaseFileInputConcurrentLength(state) {
        state.fileInputConcurrentLength++;
    },
    setDocumentMounted(state, value) {
        state.documentMounted = value;
    },
    setToBeUploadedEvent(state, value) {
        state.toBeUploadedEvent = value;
    },
};

export const actions = {
    copyFile({ commit }, file) {
        const item  = {...file, id: uuidv4()}
        commit('addFile', item)
        commit("advancedSetting/copySelection", { from:file.id, to: item.id });
        commit('increaseFileInputConcurrentLength' )
    },
    setInfo({ commit }, info) {
        if (!info) {
            return;
        }
        if (info.custom_variables && !info.customVariables) {
            info.customVariables = JSON.parse(info.custom_variables);
        }

        /**
         * In single template pages we are setting the allowed list - https://freeconvert.atlassian.net/browse/FC-3690?focusedCommentId=17445
         */
        if (
            info.template === "Single" &&
            info.customVariables &&
            info.customVariables.enablePreview
        ) {
            const sourceExt = info.source ? info.source.ext : info.slug.split("-")[0];
            const sourceAlternate =
                info.source &&
                    info.source.alternateExtensions &&
                    info.source.alternateExtensions.length
                    ? info.source.alternateExtensions
                        .map((e) => (e ? "." + e : null))
                        .join(",")
                    : "";

            const singleAllowedSource =
                (sourceAlternate ? sourceAlternate : "") + ",." + sourceExt;
            if (!info.customVariables.allowedExts)
                info.customVariables.allowedExts = singleAllowedSource;
            else
                info.customVariables.allowedExts =
                    info.customVariables.allowedExts + "," + singleAllowedSource;
        }

        commit("setInfo", info);
        if (info.advancedOptions) {
            commit(
                "advancedSetting/setPerConversionType",
                {
                    key: info.slug,
                    value: info.advancedOptions,
                },
                {
                    root: true,
                }
            );
            commit("advancedSetting/setPageSelections", {
                key: info.slug,
                value: info.advancedOptions,
            });
            if (
                !info.toolPage &&
                info.source &&
                info.source.ext &&
                info.target &&
                info.target.ext
            ) {
                commit(
                    "advancedSetting/setPerConversionType",
                    {
                        key: `${info.source.ext}-to-${info.target.ext}`,
                        value: info.advancedOptions,
                    },
                    {
                        root: false,
                    }
                );
            }
        }
    },
    async getLocalSubscription({ commit }) {
        if (localStorage.getItem("subData")) {
            try {
                const d = await aesGcmDecrypt(localStorage.getItem("subData"), pass);
                commit("setSubscription", JSON.parse(d));
            } catch (_) { }
        }
    },
    async getSubscription({ commit }, token = null) {
        try {
            let data = await getSubscription(token)

            if (!data) data = { minutes: 25, name: "Free", perTaskLimit: 1, plan: { maxFileSizeInGb: 1 }, loaded: false, free: null }

            let perTaskLimit = 0
            if (data.plan && data.planName !== 'Free' && data.plan.processKillTime) {
                // get the perTaskLimit from the pro user's current plan
                perTaskLimit = data.plan.processKillTime
            } else {
                // get the perTaskLimit from the backend for guest/free users
                const res = await getSubscriptionSettings(data.planName)
                perTaskLimit = Number(res.data.value)
            }
            const subData = { ...data, perTaskLimit }
            localStorage.setItem('subData', await aesGcmEncrypt(JSON.stringify(subData), pass))
            commit('setSubscription', subData)
        } catch (err) {
            if (err.response && err.response.status === 401) {
                localStorage.removeItem("auth");
                localStorage.removeItem("auth._token.local");
                location.assign('/auth/login');
            }
        }
    },
    async getPresets({ commit }, token) {
        const data = await getPresets(token);
        commit("savePresets", data.docs);
    },
};
