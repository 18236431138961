import Auth from "../fc/Auth/Auth";
export default function ({ $axios, $auth, redirect, store}) {
  $axios.onRequest((config) => {
    config.headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + Auth.Token
    }
  })

  $axios.onError((error) => {
    if (error.response && error.response.status === 500) {
      redirect('/error')
    }
  })
}
